.chat_parent{
    width: 100%;
    height: 100vh;
    /* background: #ECECEC; */
    box-shadow: -4px 0px 12px 0px #00000040;
    display: flex;
}
.chats{
    flex: .77;
    background: #ECECEC;
    
}