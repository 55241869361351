@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;600;700&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&family=PT+Sans+Narrow&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap");
.no_chat_main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  text-align: center;
}
.no_chat_main h2 {
  color: #1f5da0;
  font-size: 30px;
  margin-top: 15px;
}
.no_chat_main p {
  line-height: 10px;
  font-size: 12px;
  color: rgba(29, 29, 29, 0.75);
}
.no_chat_main img {
  max-width: 450px !important;
}
.hedaer_shadow {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25) !important;
}
.pop_over3 {
  top: 22px !important;
  /* left: -179px !important; */
}
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
}
.chat-body {
  flex: 1;
  overflow: auto;
  padding: 20px 70px 10px 70px;
  width: 100%;
}
.messege {
  display: flex;
  width: 100%;
  position: relative;
  margin: 2px 0px;
  border-radius: 10px;
  /* flex-direction: column; */
}
.my_messege {
  justify-content: flex-end;
}
.my_messege2nd {
  justify-content: flex-end;
}
.fnd_messege {
  justify-content: flex-start;
}
.group .name {
  position: absolute;
  top: 1.5px;
  left: 0;
  padding: 3px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 5px;
  color: #e93e3e;
}
.group .my_messege p {
  position: relative;
  right: 0;
  text-align: left;
  max-width: 60%;
  padding: 12px 70px 12px 12px;
  background: rgba(0, 123, 255, 0.21);
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13.5px;
  line-height: 20px;
  z-index: 222;
}
.my_messege2nd p {
  position: relative;
  right: 0;
  text-align: right;
  max-width: 60%;
  padding: 12px 70px 12px 12px;
  background: rgba(0, 123, 255, 0.21);
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13.5px;
  line-height: 20px;
  z-index: 222;
  margin-top: -18px;
}
.group .fnd_messege p {
  position: relative;
  right: 0;
  text-align: left;
  max-width: 60%;
  padding: 25px 70px 12px 12px;
  background: white;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13.5px;
  line-height: 20px;
  z-index: 222;
}
.fnd_messege2nd p {
  position: relative;
  right: 0;
  text-align: left;
  max-width: 60%;
  padding: 12px 70px 12px 12px;
  background: white;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13.5px;
  line-height: 20px;
  z-index: 222;
  margin-top: -18px;
}
.fnd_messege p::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  width: 20px;
  height: 25px;
  background: linear-gradient(
    235deg,
    white 0%,
    white 48%,
    transparent 50%,
    transparent
  );
}
.fnd_messege2nd p::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  width: 20px;
  height: 25px;
}
.my_messege p::before {
  content: "";
  position: absolute;
  top: 0;
  right: -7px;
  width: 20px;
  height: 25px;
  background: linear-gradient(
    135deg,
    #bad4f0 0%,
    #bad4f0 48%,
    transparent 50%,
    transparent
  );
}

.my_messege span,
.fnd_messege span,
.my_messege2nd span,
.fnd_messege2nd span {
  font-size: 8px;
  padding: 5px 8px !important;
  position: absolute;
  right: 5px;
  bottom: 0px;
  color: #1d1d1dbf;
}
.fnd_messege .img_span {
  color: #1d1d1dbf;
  position: relative;
  text-align: right !important;
  position: absolute;
  bottom: 0;
  right: 0;
}
.input_footer ,.imageDiv{
  display: flex;
  flex-basis: 95%;
  align-items: center;
  margin: 0px 10px;
  height: 50px;
  background-color: white;
  border-radius: 10px;
}
.input_footer input {
  border: none;
  outline: none;
  width: 100%;
  background: transparent;
  padding-left: 15px;
}
.input_footer .cam{
  margin-right: 15px;
  color: gray;
  position: relative;
}
.input_footer .cam input{
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 20000;
}
.cam2{
  position: relative;
  width: 200px;
  height: auto;
}
.cam2 input{
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 20000;
  top: 0;
  left: 0;
}
.send_btn .css-i4bv87-MuiSvgIcon-root {
  color: #1f5da0 !important;
  font-size: 35px;
}
.emoji_btn .css-i4bv87-MuiSvgIcon-root {
  font-size: 26px;
}
.img_reply {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}

/* --------------------- */
.messege {
  display: flex;
  width: 100%;
  position: relative;
  margin: 5px 0px;
  border-radius: 10px;
  /* flex-direction: column; */
}
.my_messege {
  justify-content: flex-end;
}
.my_messege2nd {
  justify-content: flex-end;
}
.fnd_messege {
  justify-content: flex-start;
}
.my_messege p {
  position: relative;
  left: 0 !important;
  /* text-align: start !important; */
  max-width: 60%;
  padding: 12px 70px 12px 12px;
  background: rgba(0, 123, 255, 0.21);
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13.5px;
  line-height: 20px;
  z-index: 222;
}
.my_messege2nd p {
  position: relative;
  right: 0;
  text-align: right;
  max-width: 60%;
  padding: 12px 70px 12px 12px;
  background: rgba(0, 123, 255, 0.21);
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13.5px;
  line-height: 20px;
  z-index: 222;
  margin-top: -18px;
}
.fnd_messege p {
  position: relative;
  right: 0;
  text-align: left;
  max-width: 60%;
  padding: 12px 70px 12px 12px;
  background: white;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13.5px;
  line-height: 20px;
  z-index: 222;
}
.fnd_messege2nd p {
  position: relative;
  right: 0;
  text-align: left;
  max-width: 60%;
  padding: 12px 70px 12px 12px;
  background: white;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13.5px;
  line-height: 20px;
  z-index: 222;
  margin-top: -18px;
}
.fnd_messege p::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  width: 20px;
  height: 25px;
  background: linear-gradient(
    235deg,
    white 0%,
    white 48%,
    transparent 50%,
    transparent
  );
}
.fnd_messege2nd p::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  width: 20px;
  height: 25px;
}
.my_messege p::before {
  content: "";
  position: absolute;
  top: 0;
  right: -7px;
  width: 20px;
  height: 25px;
  background: linear-gradient(
    135deg,
    #bad4f0 0%,
    #bad4f0 48%,
    transparent 50%,
    transparent
  );
}

.my_messege span,
.fnd_messege span,
.my_messege2nd span,
.fnd_messege2nd span {
  font-size: 8px;
  padding: 5px 8px !important;
  position: absolute;
  right: 0px;
  bottom: 0px;
  color: #1d1d1dbf;
}
.epr-main{
  position: absolute !important; 
  bottom: 60px!important;
  z-index: 20000 !important;
}
.attach_container{
  position: absolute;
  bottom: 11% !important;
  left: 5% !important;
  z-index: 3000;
  transition: .5s all ease-in-out;
}
.attach_container div{
  /* padding: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-top: 10px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  position: relative;
}
.attach_container div input{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2000;
}
.image_div_last{
  padding: 15px 15px 20px 14px!important;
  background-color: aliceblue;
  border-radius: 10px;
  position: relative;
  /* margin-bottom: 20px; */
}
.image_div_last .image_span_last{
  position: absolute;
  right: 0;
  bottom: -5px;
  padding: 10px;
  color: black;
}
.download button:hover{
  background-color: blue;
  color: white !important;
  transition: .4s all ease;
}
