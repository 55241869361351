@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;600;700&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400&family=PT+Sans+Narrow&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap');

.login_main{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader_main{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.main_bg{
    background-color: #1F5DA0;
}
.login_main .login_text{
    font-family: 'Poppins', sans-serif;
    font-size: 2.3em;
    font-weight: 600;
    color: #1D1D1DBF;
    text-align: center;
    margin:1em 0em ;
}
.logo_image{
    display: flex;
    justify-content: center;
}
.input_container .number {
    border: 1px solid #1F5DA0;
    display: flex; 
    align-items: center;
    height: 48px;
    padding: 0px 8px;
    border-radius: 10px;
    width:  400px;
}
.input_container span{
    font-size: 12px;
    color: #1F5DA0;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 2px;
    margin-left: 3px;
}
.input_container select {
    border: 0;
    outline: none;
    color: #1D1D1DBF;
    padding: 3px;
}
.input_container input ,.password input{
    border: 0 ;
    outline: none;
    color: #1D1D1DBF;
    padding: 0px 6px;
    background: transparent;
    
}
.input_container input::-webkit-outer-spin-button,
.input_container input::-webkit-inner-spin-button {
  display: none;
}
.create .number {
    border: 1px solid #1F5DA0;
    display: flex; 
    align-items: center;
    height: 48px;
    border-radius: 10px;
    width:  400px;
}
.number2{
    border: 1px solid #1F5DA0;
    display: flex; 
    align-items: center;
    height: 48px;
    border-radius: 10px;
    width:  180px !important;  
}
.number .divider{
    height: 65%;
    width: 1px;
    background-color: #00000066;
    margin: 8px 2px;
}
.input_container button{
    border-radius: 10px;
    outline: none;
    border: 1px solid #1F5DA0;
    height: 48px;
    color: white;
    background-color: #1F5DA0;
    font-size: 20px;
    font-weight: 500;
}
.logo_image .cam{
    position: absolute;
    right: 0;
    bottom: 7px;
}
.logo_image .profile{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 20000;
    left: 0;
}
.number .icons-div{
    height: 101%;
    background-color: #1F5DA0;
    padding: 5px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: -1px;
    margin-left: -9px;
    overflow: hidden;
}
.create .number input {
    color: #1F5DA0 !important;
    padding-left: 14px !important;
    font-size: 14px;
    width: 100%;
}
.create .number input:focus{
    border: none;
    outline: 0;
}
.name{
    width: 400px !important;
}
.tic{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.verify-text{
    color: #1F5DA0;
    font-size: 14px;
    margin-top: -18px !important;
}
.code_div div{
    width: 61px;
    height: 61px;
    background-color: #F2F2F2;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: baseline;
}
.code_div div input {
    border-bottom: 1px solid #00000080;
    border-top: none!important;
    border-left: none!important;
    border-right: none!important;
    outline: none;
    width: 40%;
    height: 70%;
    background-color: #F2F2F2;
    /* border-radius: 10px; */
    /* padding-left: 5px; */
    /* margin: 5px; */
}
.last_text{
    font-size: 19px;
    color: #000000BF;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.send_again{
    text-decoration: underline;
    color: #1F5DA0;
    font-weight: semibold;
    cursor: pointer;
    font-family: "Popins";
}
.create_redirect{
    text-align: right;
    font-size: 12px;
    margin-top: 15px;
    cursor: pointer;
}
.colord{
    color: #1F5DA0;
}
.verify-main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.verify-text{
    color: #1F5DA0;
    font-size: 16px;
    /* margin-top: -18px !important; */
    text-align: center !important;
}
@media only screen and (max-width: 400px) {
    .input_container .number {
        width:  340px;
    }
    .name{
        width: 340px !important;
    }
    .number2{
        width: 140px !important;
    }
}