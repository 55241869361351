.settings_profile {
  display: flex;
  align-items: center;
  padding: 5px 0px 5px 15px;
  margin: 10px !important;
}
.settings_profile img {
  width: 90px;
  height: 90px;
}
.settings_profile h2 {
  color: #1f5da0;
  font-size: 24px;
  margin-left: 15px;
}
.settings_div {
  display: flex;
  align-items: center;
  padding: 3px 24px;
  cursor: pointer;
}
.settings_div h5 {
  font-size: 20px;
  margin-left: 15px;
  color: #1d1d1dbf;
  margin: auto 0 auto 15px;
}
.settings_icon {
  padding: 6px;
  border-radius: 50%;
  background-color: #1f5da0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.settings_icon .css-i4bv87-MuiSvgIcon-root {
  color: white !important;
  font-size: 18px !important;
}
