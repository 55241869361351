.side_bar {
  flex: 0.33;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.sidebar_hedar {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  align-items: center;
  background: #ececec;
}
.sidebar_header_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 4vw;
  /* margin: 15px 0px; */
}
.sidebar_header_right2 {
  padding: 2px 20px;
}
.left_user {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  margin-top: 10px;
}
.sidebar_search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search input {
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #ececec;
  padding-left: 40px;
  margin-left: 10px;
  height: 45px;
  /* flex: 0.80; */
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.search .css-i4bv87-MuiSvgIcon-root {
  margin-right: -40px !important;
  z-index: 333 !important;
  color: gray;
  margin: 4px 5px;
}
.search {
  display: flex;
  align-items: center;
  flex-basis: 90%;
}
.search {
  display: flex;
  align-items: center;
  flex-basis: 97%;
}
.sidebar_chats {
  cursor: pointer;
  /* flex: 1; */
  overflow: auto !important;
  /* visibility: hidden !important; */
  margin-bottom: 10px;
  
}
.sidebar_chats2 {
  overflow: auto;
  min-height: 80vh;
  /* background: #000; */
}
::-webkit-scrollbar {
  width: 0.5rem !important;
}
::-webkit-scrollbar-track {
  /* background-color: #5a4d4d; */
}
::-webkit-scrollbar-thumb {
  background-color: #9f9898 !important;
  border-radius: 1rem;
}
.single_chat {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.single_chat:hover{
  background: #ECECEC !important;
  transition: .4s all ease;
}
.is_chat_active{
  background: #ECECEC !important;
}
.letest {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px;
  line-height: 18px;
  color: #1d1d1d !important;
}
.chat_name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: rgba(29, 29, 29, 0.75);
  padding-top: 7px;
}
.chat_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(29, 29, 29, 0.6);
  /* padding-bottom: 20px !important; */
  margin-top: -8px !important;
}
.pop_over {
  top: 55px !important;
  left: -122px !important;
}
.pop_over2 {
  top: 70px !important;
  left: -179px !important;
}
.privacy .css-i4bv87-MuiSvgIcon-root {
  color: #1f5da0 !important;
}
.new_perticipate {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.new_perticipate_users {
  overflow: auto;
}
.active_container {
  position: relative;
}
.active_container img {
  width: 60px;
  height: 60px;
}
.tic_icon {
  position: absolute;
  bottom: 25%;
  right: -10px;
  padding: 4px;
  background-color: #48f063;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
}
.tic_icon .css-i4bv87-MuiSvgIcon-root {
  font-size: 14px;
  color: white;
}
.group_perticapite {
  margin: 10px 20px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-wrap: wrap;
}
.group_perticapite2 {
  margin: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.perticipate {
  display: flex;
  flex-basis: 45%;
  align-items: center;
  border-radius: 12px;
  background: rgba(236, 236, 236, 0.79);
  padding: 2px 0px;
  margin-top: 8px;
  justify-content: space-between;
}
.perticipate2 {
  display: flex;
  flex-basis: 45%;
  align-items: center;
  border-radius: 12px;
  background: rgba(236, 236, 236, 0.79);
  padding: 2px 0px;
  margin-top: 8px;
  justify-content: space-around;
}
.ml-15 {
  margin-left: 15px;
}
.perticipate .img_name {
  display: flex;
  justify-content: space-between;
}
.perticipate .css-i4bv87-MuiSvgIcon-root {
  font-size: 16px;
  justify-content: end;
  margin-right: 10px;
  cursor: pointer;
}
.group_footer {
  width: 100%;
  height: 100px;
  /* background: rgba(31, 93, 160, 0.2); */
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.group_footer div {
  border-radius: 50%;
  /* padding: 6px; */
  background-color: #1f5da0;
  cursor: pointer;
}
.group_footer .css-i4bv87-MuiSvgIcon-root {
  color: white;
  font-size: 22px;
}
.group_photo_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 40px;
  width: 100%;
}
.group_photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  position: relative;
  cursor: pointer;
}
.group_photo input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2222 !important;
}
.top_div2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* transform: translate(-50%,-50%); */
  border-radius: 50%;
  background-color: #727272;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  z-index: 100;
}
.top_div2 span {
  font-size: 15px !important;
  color: white;
  margin-top: -10px;
}
.group_name {
  margin: 10px 30px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #1f5da0;
}
.group_name input {
  flex: 1;
  border: none;
  outline: none;
  color: rgba(29, 29, 29, 0.3);
  padding-left: 6px;
}
.group_name .css-i4bv87-MuiSvgIcon-root {
  color: rgba(29, 29, 29, 0.3) !important;
}
.member {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: rgba(29, 29, 29, 0.75);
}
.chat_image{
  position: relative;
  width: 55px;
  height: 55px;
  
}
.chat_image div{
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #48f063;
  top: 44%;
  right: -12% !important;
  border: 2px solid white;
}
.black{
  color: #1d1d1d;
  font-weight: 500;
}
